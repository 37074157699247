import React from 'react';
import { FormattedMessage, IntlContextConsumer, injectIntl } from 'gatsby-plugin-intl';

import Layout from 'components/layout';
import SEO from 'components/seo';
import { Global } from '@emotion/core';
import Helmet from 'react-helmet';


const NotFoundPage = ({ intl }) => (
  <>
    <Global
      styles={theme => ({
        body: {
          fontFamily: theme.fonts.body,
        }
      })}
    />
    <Layout>
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) => (
          <SEO
            title={"404"}
            lang={currentLocale}
            description={intl.formatMessage({ id: "oops_text" })}
          />
        )}
      </IntlContextConsumer>
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) => (
          <Helmet>
            <link rel="alternate" hrefLang="x-default" href="https://couponise.app/404/" />
            {languages.map(language => {
              if(language !== intl.defaultLocale) {
                return (
                  <link key={language} rel="alternate" hrefLang={`${language}`} href={`https://couponise.app/${language}/404/`} />
                )
              }
            })}
          </Helmet>
        )}
      </IntlContextConsumer>
      <div style={{ height: '70vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <div style={{ textAlign: 'center' }}>
          <h1><FormattedMessage id="oops" /></h1>
          <p><FormattedMessage id="oops_text" /></p>
        </div>
      </div>
    </Layout>
  </>
);

export default injectIntl(NotFoundPage);
